import find from 'lodash/find';
import { IWorkflow as _IWorkflow, IWorkspace } from '#/types/workspace';

type IWorkflow = IWorkspace | _IWorkflow;

function findNode(workflow: IWorkflow, nodeQuery) {
  return find(workflow?.nodes, nodeQuery);
}

export function cronScheduleFromWorkflow(workflow: IWorkflow) {
  const cronNode = findNode(workflow, {
    data: {
      type: 'trigger',
      triggerType: 'cron',
    },
  });
  return cronNode ? cronNode?.data?.inputValues?.schedule : null;
}
