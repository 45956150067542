// #/services/workspaces/mockData/workflow.ts

import { v4 as uuidv4 } from 'uuid';
import { Edge, Node } from 'reactflow';

import { IWorkspace } from '#/types/workspace';

import createActionNode from '#/services/workspaces/nodes/createActionNode';
import createTriggerNode from '#/services/workspaces/nodes/createTriggerNode';

export async function createDefaultWorkspace() {
  const nodes: Node[] = await Promise.all([
    createTriggerNode({
      id: '1',
      position: { x: 0, y: 0 },
      data: {
        uuid: uuidv4(),
        triggerType: 'cron',
        logoUrl: 'https://alpha.simpfi.ai/simpfinogrid.png',
        inputValues: {
          schedule: '0 12 * * *',
        },
      },
    }),
    createActionNode({
      id: '2',
      position: { x: 150, y: 150 },
      data: {
        uuid: uuidv4(),
        serviceKey: 'openai',
        logoUrl: 'https://logo.clearbit.com/openai.com?size=256',
        actionKey: 'post /chat/completions',
        actionVerb: 'POST',
        actionEndpoint: 'https://api.openai.com/v1/chat/completions',
        inputValues: {
          headers: {
            Authorization: 'Bearer <%= ctx.OPENAI_API_KEY %>',
          },
          bodyJson: {
            // model: 'text-davinci-003',
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'user',
                content:
                  'Using less than 140 characters, generate a random tweet about the future of AI and workflow automations. NEVER USE CHARACTERS SUCH AS "" I.E. DOUBLE QUOTES',
              },
            ],
            temperature: 0.7,
            max_tokens: 200,
          },
        },
      },
    }),
    createActionNode({
      id: '3',
      position: { x: 350, y: 300 },
      data: {
        uuid: uuidv4(),
        serviceKey: 'twitter-v2',
        logoUrl: 'https://logo.clearbit.com/twitter.com?size=256',
        actionKey: 'post /2/tweets',
        actionVerb: 'POST',
        actionEndpoint: 'https://api.twitter.com/2/tweets',
        prerequisites: ['OAUTH_1_0A'],
        inputValues: {
          headers: {},
          bodyJson: {
            text: '<%= prevNodeOutput.choices[0].message.content %>',
          },
        },
      },
    }),
  ]);

  const edges: Edge[] = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
    },
  ];

  const workspace: IWorkspace = {
    uuid: uuidv4(),
    name: 'New Workspace',
    description: 'My first workspace',
    icons: [],
    context: {},
    prerequisites: ['OAUTH_1_0A'],
    nodes,
    edges,
  };

  return workspace;
}

export default createDefaultWorkspace;
