// #/services/workspaces/writeWorkspace.ts

import { join as pathJoin } from 'path';

import upsertJsonFile from '#/services/s3/actions/json/upsertJsonFile';
import { IWorkflow } from '#/types/workspace';

import { PATH_USERS } from '../s3/filepaths';

export async function upsertWorkflowJson(args: {
  ownerAddress: string;
  workflowUuid: string;
  json: Partial<IWorkflow>;
}): Promise<IWorkflow> {
  const { ownerAddress, workflowUuid, json } = args;

  const userHome = pathJoin(PATH_USERS, ownerAddress);
  const userWorkflowsFolderKey = pathJoin(userHome, 'workflows/');
  const workflowKey = pathJoin(
    userWorkflowsFolderKey,
    `${workflowUuid}.json`,
  );

  // TODO: overwrite instead of merge
  return await upsertJsonFile({
    s3ObjectKey: workflowKey,
    updatedJson: json,
  }) as IWorkflow;
}

export async function upsertWorkflowVersionJson(args: {
  ownerAddress: string;
  workflowUuid: string;
  json: IWorkflow;
}): Promise<IWorkflow> {
  const { ownerAddress, workflowUuid, json } = args;

  const version = json?.version;

  const userHome = pathJoin(PATH_USERS, ownerAddress);
  const userWorkflowVersionsFolderKey = pathJoin(
    userHome,
    'workflows',
    workflowUuid,
    'versions/',
  );
  const workflowVersionKey = pathJoin(
    userWorkflowVersionsFolderKey,
    `${version}.json`,
  );

  // TODO: overwrite instead of merge
  return await upsertJsonFile({
    s3ObjectKey: workflowVersionKey,
    updatedJson: json,
  }) as IWorkflow;
}

export async function writeWorkflow(args: {
  ownerAddress: string;
  workflowUuid: string;
  workflow: IWorkflow;
}): Promise<IWorkflow> {
  try {
    const date = new Date();
    const timestampMs = date.getTime();
    const timestampIso = date.toISOString();

    const { ownerAddress, workflowUuid, workflow } = args;

    const version = timestampMs;
    const updatedAt = timestampIso;

    const newWorkflow = {
      ...workflow,
      version,
      updatedAt,
    };

    // TODO: overwrite instead of merge
    await upsertWorkflowJson({
      ownerAddress,
      workflowUuid,
      json: newWorkflow,
    })

    // TODO: overwrite instead of merge
    await upsertWorkflowVersionJson({
      ownerAddress,
      workflowUuid,
      json: newWorkflow,
    })

    return newWorkflow;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error writing workflow: ${error.message}`);
  }
}
export default writeWorkflow;
