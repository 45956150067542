// #/services/workspaces/nodes/createTriggerNode.ts

import { v4 as uuidv4 } from 'uuid';

import {
  NodeType,
  TriggerNode,
  TriggerNodeData,
  TriggerType,
} from '#/types/workspace';
import parseCronString from '#/utils/parseCronString';

export const CronInputSchema = {
  properties: {
    schedule: 'string',
  },
  required: ['schedule'],
  type: 'object',
};
export const CronDefaultInputValues = {
  schedule: '0 12 * * *',
};

export const SwitchInputSchema = {
  properties: {
    condition: 'string',
    cases: 'string',
  },
  required: ['condition', 'cases'],
  type: 'object',
};
export const SwitchDefaultInputValues = {
  condition: '<%= ["RED", "GREEN", "BLUE"][Math.floor(Math.random() * 3)] %>',
  cases: 'RED,GREEN,BLUE',
};

export const WebhookInputSchema = {
  properties: {
    sourceDomain: 'string',
  },
  required: ['sourceDomain'],
  type: 'object',
};
export const WebhookDefaultInputValues = {
  sourceDomain: 'https://simpfi.ai',
}

export function titleSublineDetailsFromTriggerNodeData(
  nodeData: TriggerNodeData,
) {
  const _title = nodeData.title;
  const triggerType = nodeData.triggerType;
  const schedule = nodeData.inputValues?.schedule;

  let cronScheduleDetail;
  if (triggerType === 'cron' && schedule != null && schedule !== '') {
    cronScheduleDetail = parseCronString(schedule);
  }

  let title = 'triggers',
    subline: string = triggerType,
    detail = cronScheduleDetail;
  if (_title != null && _title !== '' && _title !== title) {
    title = _title;
    subline = `triggers/${triggerType}`;
    detail = cronScheduleDetail;
  }

  return { title, subline, detail };
}

export const createTriggerNode = (args: {
  id: string;
  position: { x: number; y: number };
  type?: NodeType;
  data: {
    uuid?: string;
    isLateral?: boolean;
    title?: string;
    triggerType: TriggerType;
    logoUrl?: string;
    inputSchema?: Record<string, any>;
    inputValues?: Record<string, any>;
  };
}): TriggerNode => {
  const {
    id,
    position,
    type: nodeType = 'turbo',
    data: {
      uuid = uuidv4(),
      isLateral = false,
      title: _title,
      triggerType,
      logoUrl = 'https://alpha.simpfi.ai/simpfinogrid.png',
      inputSchema,
      inputValues,
      // ...restData
    },
    // ...rest
  } = args;

  const { title, subline, detail } = titleSublineDetailsFromTriggerNodeData(
    args.data as TriggerNodeData,
  );

  return {
    // ...rest,
    id,
    position,
    type: nodeType,
    data: {
      // ...restData,
      uuid,
      type: 'trigger',
      triggerType,
      title,
      subline,
      detail,
      logoUrl,
      icons: ['FunctionIcon'],
      isLateral,
      inputSchema:
        inputSchema ?? (
          triggerType === 'cron' ? CronInputSchema :
            triggerType === 'switch' ? SwitchInputSchema :
              triggerType === 'webhook' ? WebhookInputSchema : {}
        ),
      inputValues: {
        ...(
          triggerType === 'cron' ? CronDefaultInputValues :
            triggerType === 'switch' ? SwitchDefaultInputValues :
              triggerType === 'webhook' ? WebhookDefaultInputValues : {}
        ),
        ...(inputValues ?? {}),
      },
    },
  };
};

export default createTriggerNode;
