// #/services/workspaces/writeWorkspace.ts

import { join as pathJoin } from 'path';

import upsertJsonFile from '#/services/s3/actions/json/upsertJsonFile';
import { IWorkspace } from '#/types/workspace';
import { PATH_USERS } from '../s3/filepaths';

export default async function writeWorkspace(args: {
  ownerAddress: string;
  workspaceUuid: string;
  workspace: IWorkspace;
}): Promise<IWorkspace> {
  try {
    const date = new Date();
    const timestampMs = date.getTime();
    const timestampIso = date.toISOString();

    const { ownerAddress, workspaceUuid, workspace } = args;

    const userHome = pathJoin(PATH_USERS, ownerAddress);
    const userWorkspacesFolderKey = pathJoin(userHome, 'workspaces/');
    const workspaceKey = pathJoin(
      userWorkspacesFolderKey,
      `${workspaceUuid}.json`,
    );

    const newWorkspace = {
      ...workspace,
      updatedAt: timestampIso,
    };

    await upsertJsonFile({
      s3ObjectKey: workspaceKey,
      updatedJson: newWorkspace,
    });

    const userWorkspaceVersionsFolderKey = pathJoin(
      userHome,
      'workspaces',
      workspaceUuid,
      'versions/',
    );
    const workspaceVersionKey = pathJoin(
      userWorkspaceVersionsFolderKey,
      `${timestampMs}.json`,
    );

    await upsertJsonFile({
      s3ObjectKey: workspaceVersionKey,
      updatedJson: newWorkspace,
    });

    return newWorkspace;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error writing workspace: ${error.message}`);
  }
}
