// #/services/s3/actions/json/readJsonFile.ts

import superjson from 'superjson';

import { AWS_S3_BUCKET } from '#/config/constants';
import getS3Object from '../getS3Object';

function parseS3ObjectBodyJson<T = Record<string, any>>(args: {
  s3Object: any;
}) {
  const { s3Object } = args;

  if (!s3Object?.Body) {
    return {} as T;
  }

  const json = superjson.parse<T>(
    typeof s3Object?.Body !== 'string'
      ? s3Object?.Body?.toString()
      : s3Object?.Body,
  );

  return json;
}

export async function readJsonFile<T = Record<string, any>>(args: {
  s3ObjectKey: string;
}): Promise<T | null> {
  try {
    const { s3ObjectKey } = args;

    const s3Object = await getS3Object({
      Bucket: AWS_S3_BUCKET,
      Key: s3ObjectKey,
    });

    if (s3Object == null) {
      return null;
    }

    return parseS3ObjectBodyJson<T>({ s3Object });
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error reading JSON file: ${error.message}`);
  }
}

export default readJsonFile;
