import LogRocket from 'logrocket';

import { LOG_ROCKET_PROJECT } from '#/config/env';

LogRocket.init(LOG_ROCKET_PROJECT);

export function identifyUser(args: { user: any; userSession: any }) {
  const { user, userSession } = args;
  const userAddress = userSession?.address;
  if (!user || !userSession || !userAddress) {
    return;
  }
  LogRocket.identify(userAddress, {
    // name: 'James Morrison',
    // email: 'jamesmorrison@example.com',
    address: userAddress,

    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
  });
}
